<!-- 完成对账弹窗 -->
<template>
  <el-dialog
    width="30%"
    center
    :visible="visible"
    :lock-scroll="false"
    :destroy-on-close="true"
    custom-class="ele-dialog-form"
    :title="isUpdate?'完成对账':'完成对账'"
    @update:visible="updateVisible">
    <el-form
      ref="form"
      :model="form"
      :rules="rules"
      label-width="120px">

      <el-form-item label="客户名称：">
        <span>{{data.channel_name}}</span>
      </el-form-item>
      <el-form-item label="对账周期：">
        <span v-for="(item,index) in data.period" style="margin-right: 10px;">{{item}}</span>
      </el-form-item>
      <el-form-item label="订单总量：">
        <span>{{data.order_count}}</span>
      </el-form-item>
      <el-form-item label="合同价：">
        <span>{{data.contract_price}}</span>
      </el-form-item>
      <el-form-item label="收款账户：" prop="value">
        <el-select clearable v-model="form.collection_id" placeholder="账号A,账户名称B,C银行">
          <el-option v-for="(item) in select" :label="item.bank_number" :value="parseInt(item.id)"/>
        </el-select>
        <el-button style="background: #3F4157!important;border-color: #3F4157!important;color: #FFFFFF!important;margin-left: 20px;" @click="To">管理收款账户</el-button>
      </el-form-item>
      <el-form-item label="整体补扣款：">
        <el-input
          placeholder="请输入整体补扣款"
          v-model="form.entirety_repair"
          clearable>
        </el-input>
      </el-form-item>
      <el-form-item label="整体补扣款原因">
        <el-input
          clearable
          type="textarea"
          :autosize="{ minRows: 3, maxRows: 5}"
          placeholder="请输入内容"
          v-model="form.entirety_repair_reason">
        </el-input>
      </el-form-item>
    </el-form>


    <div slot="footer">
      <el-button
        @click="updateVisible(false)">取消
      </el-button>
      <el-button
        style="margin-left: 20px;"
        type="primary"
        :loading="loading"
        @click="save">确认完成
      </el-button>
    </div>

  </el-dialog>
</template>

<script>

import {get_collection_profile, wanchengremittance} from "@/api/finance";

export default {
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 修改回显的数据
    data: Object
  },
  components: { },
  data() {
    return {
      // 表单数据
      form: Object.assign({}, this.data),
      // 验证规则
      rules: {
        collection_id: [
          {required: true, message: '请选择收款账户', trigger: 'change'}
        ],
      },
      // 提交状态
      loading: false,
      // 是否是修改
      isUpdate: false,

      //收款资料下拉列表接口
      select:[],

    };
  },

  mounted() {
    if (this.data) {
      console.log(this.data)
      this.isUpdate = true;
    } else {
      this.form = {};
      this.isUpdate = false;
    }

    //获取收款资料下拉列表接口
    this.getSelect();
  },
  methods: {
    // 获取下拉
    getSelect(){
      get_collection_profile().then(res => {
        this.select = res.data;
      })
    },

    /* 保存编辑 */
    save() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          let data = {
            collection_id: this.form.collection_id,
            entirety_repair: this.form.entirety_repair,
            entirety_repair_reason:this.form.entirety_repair_reason
          }
          wanchengremittance(this.data.id,data).then(res => {
            console.log(res)
            this.loading = false;
            if (res.code === 200) {
              this.$message.success(res.msg);
              if (!this.isUpdate) {
                this.form = {};
              }
              this.updateVisible(false);
              this.$emit('done');
            } else {
              this.$message.error(res.msg);
            }
          }).catch(e => {
            this.loading = false;
            this.$message.error(e.msg);
          })
        } else {
          return false;
        }
      });
    },
    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value);
    },

    To(){
      this.updateVisible(false);
      this.$router.push('/reconciliationInformation')
    },


  }
}
</script>

<style scoped lang="scss">

</style>
